import { Add, ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HeroImage from "assets/images/home-hero.png";
import DependentsTable from "./DependentsTable";
import axios from "axios";
import { SERVER_ID, SITE_ID } from "env";
import useAuth from "helpers/hooks/useAuth";
import PreLoader from "components/tools/PreLoader";

const Parent = () => {
  const { auth } = useAuth();
  const [dependents, setDependents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getDependents = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/dependents/${SERVER_ID}/${SITE_ID}/${auth?.user?.CustomerNumber}`
      );
      console.log(data, "depent");
      setDependents(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error getting dependents");
    }
  }, [auth]);

  useEffect(() => {
    getDependents();
  }, [getDependents]);

  if (loading) {
    return <PreLoader />;
  }
  return (
    <>
      <Box
        minWidth={"100%"}
        sx={{
          height: 300,
          background: `linear-gradient(rgba(255, 255, 255, .1), rgba(100, 161, 74, .6)),url(${HeroImage})no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <Toolbar />

        <Container
          maxWidth="md"
          sx={{
            paddingY: 2,
            paddingX: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 0,
          }}
        >
          <Box>
            <IconButton onClick={() => navigate(-1)} sx={{}}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box marginTop={2}>
            <Typography variant="h5" color="white" fontWeight={500}>
              You now have
            </Typography>
            <Typography variant="h4" color="white" fontWeight={500}>
              <strong>{dependents.length}</strong> Dependents
            </Typography>
            {/* <Button
              variant="contained"
              sx={{
                marginTop: 1,
                letterSpacing: "1px",
              }}
              startIcon={<Add />}
              onClick={() => navigate("/dependent/add")}
            >
              Add Dependents
            </Button> */}
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          marginTop: "-40px",
          background: "#fff",
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            padding: 2,
          }}
        >
          <DependentsTable dependents={dependents} />
        </Container>
      </Box>
    </>
  );
};

export default Parent;
