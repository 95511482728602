import { createTheme } from "@mui/material/styles";

export const colors = {
  grey: {
    100: "#141414",
    200: "#292929",
    300: "#3d3d3d",
    400: "#525252",
    500: "#666666",
    600: "#858585",
    700: "#a3a3a3",
    800: "#c2c2c2",
    900: "#e0e0e0",
  },
  primary: {
    100: "#0f2922",
    200: "#1e5245",
    300: "#2e7c67",
    400: "#64a14a",
    500: "#4cceac",
    600: "#70d8bd",
    700: "#94e2cd",
    800: "#b7ebde",
    900: "#dbf5ee",
  },
  redAccent: {
    100: "#2c100f",
    200: "#58201e",
    300: "#832f2c",
    400: "#af3f3b",
    500: "#db4f4a",
    600: "#e2726e",
    700: "#e99592",
    800: "#f1b9b7",
    900: "#f8dcdb",
  },
  blueAccent: {
    100: "#151632",
    200: "#2a2d64",
    300: "#3e4396",
    400: "#535ac8",
    500: "#6870fa",
    600: "#868dfb",
    700: "#a4a9fc",
    800: "#c3c6fd",
    900: "#e1e2fe",
  },
  pinkAccent: {
    500: "#ff3e8b",
  },
};

// mui theme settings
export const themeSettings = {
  palette: {
    mode: "light",
    primary: {
      main: colors.primary[400],
    },
    secondary: {
      main: colors.blueAccent[500],
    },
    accent: {
      main: colors.pinkAccent[500],
    },
    neutral: {
      dark: colors.grey[700],
      main: colors.grey[500],
      light: colors.grey[100],
    },
    background: {
      default: "#ffff",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    fontSize: 12,
    h1: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 40,
    },
    h2: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 32,
    },
    h3: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 24,
    },
    h4: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 20,
    },
    h5: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 16,
    },
    h6: {
      fontFamily: ["Montserrat", "sans-serif"].join(","),
      fontSize: 14,
    },
  },
};

const theme = createTheme(themeSettings);

export default theme;
