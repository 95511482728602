import { Add, ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import HeroImage from "assets/images/home-hero.png";
import DependentsTable from "./DependentsTable";
import axios from "axios";
import { SERVER_ID, SITE_ID } from "env";
import useAuth from "helpers/hooks/useAuth";
import PreLoader from "components/tools/PreLoader";
import { stringAvatar } from "helpers/globalfunction";

const Parent = () => {
  const { auth } = useAuth();
  const [dependents, setDependents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  console.log(auth);
  const getDependents = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/dependents/${SERVER_ID}/${SITE_ID}/${auth?.user?.CustomerNumber}`
      );
      console.log(data, "depent");
      setDependents(data);
      setLoading(false);
    } catch (error) {
      console.log(error, "error getting dependents");
    }
  }, [auth]);

  useEffect(() => {
    getDependents();
  }, [getDependents]);

  if (loading) {
    return <PreLoader />;
  }
  return (
    <>
      <Box
        minWidth={"100%"}
        sx={{
          height: 300,
          background: `linear-gradient(rgba(100, 161, 74, .3), rgba(100, 161, 74, 1))  80%`,
          backgroundSize: "cover",
        }}
      >
        <Toolbar />

        <Container
          maxWidth="md"
          sx={{
            paddingY: 2,
            paddingX: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 0,
          }}
        >
          <Box>
            <IconButton onClick={() => navigate(-1)} sx={{}}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box marginTop={2}>
            <Typography variant="h5" color="white" fontWeight={500}>
              You now have
            </Typography>
            <Typography variant="h4" color="white" fontWeight={500}>
              <strong>{dependents.length}</strong> Dependents
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: "#fff",
          padding: 2,
        }}
      >
        <Paper
          sx={{
            marginTop: "-100px",
            background: "#fff",
            borderRadius: 5,
            minHeight: "65vh",
          }}
          elevation={3}
        >
          <Container
            maxWidth="md"
            sx={{
              padding: 2,
            }}
          >
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: -8,
                marginBottom: 2,
                zIndex: 10,
              }}
            >
              <Avatar
                alt={auth.user.FirstName}
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/dependent-images/" +
                  auth.user.FirstName.replace(" ", "-").toLowerCase() +
                  ".png"
                }
                {...stringAvatar("John", {
                  width: 100,
                  height: 100,
                  fontSize: "calc(100% + 20px)",
                })}
              />
            </Box>
            <Box
              textAlign="center"
              sx={{
                maxWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography variant="h4" fontWeight={500}>
                {auth.user.FirstName}
              </Typography>
              <Typography variant="h5" fontWeight={500}>
                {auth.user.Email}
              </Typography>
              <Typography variant="h5" fontWeight={500}>
                Customer Number:{" "}
                <Typography component="span" variant="h5" fontWeight="400">
                  {auth.user.CustomerNumber}
                </Typography>
              </Typography>
            </Box>
            <DependentsTable dependents={dependents} />
          </Container>
        </Paper>
      </Box>
    </>
  );
};

export default Parent;
