import { useEffect, useState } from "react";
import {
  AudioConfig,
  SpeechConfig,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";

const useTts = (defaultText) => {
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState(defaultText);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const speechConfig = SpeechConfig.fromSubscription(
        "3d965505232447a9aa19175d6826ae24",
        "southeastasia"
      );

      speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
      const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
      const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

      synthesizer.speakTextAsync(
        text,
        (result) => {
          if (result.errorDetails) {
            console.error(result.errorDetails);
          } else {
            setDuration(Math.round(result.audioDuration / 10000));
          }
        },
        (err) => console.log(err)
      );
      setLoading(false);
    }
  }, [text]);

  return [text, setText, loading, duration];
};

export default useTts;
