import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ChevronLeft, Delete, MonetizationOn } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import { useDropzone } from "react-dropzone";
import { SERVER_ID, SITE_ID } from "env";
import axios from "axios";
import PreLoader from "components/tools/PreLoader";
import { stringAvatar } from "helpers/globalfunction";
import useAuth from "helpers/hooks/useAuth";

const dependents = [
  {
    id: 1,
    badgeNo: "001",
    fullName: "John Doe",
    fundBalance: 1000,
  },
  {
    id: 2,
    badgeNo: "002",
    fullName: "Jane Doe",
    fundBalance: 500,
  },
  {
    id: 3,
    badgeNo: "003",
    fullName: "Allen Doe",
    fundBalance: 1500,
  },
];

const DependentDetails = () => {
  const params = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [dependent, setDependent] = useState({});

  const getDependentDetails = useCallback(async () => {
    try {
      // const { data } = await axios.get(
      //   `https://ecommv2.servingintel.com/customer/dependents/${SERVER_ID}/${SITE_ID}/${params?.dependentId}`
      // );

      // Workaround
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/dependents/${SERVER_ID}/${SITE_ID}/${auth?.user?.CustomerNumber}`
      );

      const dependentDetails = data.find(
        (dep) => dep.BadgeNo === parseInt(params?.dependentId)
      );

      setDependent(dependentDetails);
      setLoading(false);
    } catch (error) {
      console.log(error, "error getting dependents");
    }
  }, [params, auth?.user?.CustomerNumber]);

  useEffect(() => {
    getDependentDetails();
  }, [getDependentDetails]);

  const navigate = useNavigate();

  // For Improvement
  const [open, setOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRemove = async () => {
    try {
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/delete-dependent/${SERVER_ID}/${SITE_ID}/${params?.dependentId}`
      );
      if (data) {
        navigate("/parent");
      }
    } catch (error) {}
  };

  if (loading) {
    return <PreLoader />;
  }

  return (
    <>
      <Box
        minWidth={"100%"}
        sx={{
          height: 350,
          background: `#F5F5F5`,
          backgroundSize: "cover",
          // borderBottomLeftRadius: 50,
          // borderBottomRightRadius: 50,
        }}
      >
        <Toolbar />

        <Container
          maxWidth="md"
          sx={{
            paddingY: 2,
            paddingX: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 0,
          }}
        >
          <Box>
            <IconButton onClick={() => navigate("/parent")} color="inherit">
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              sx={{
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
              }}
              flexDirection={"column"}
              gap={2}
              width="fit-content"
              margin="auto"
            >
              <Box
                sx={{
                  padding: 1,
                  background: "#E4E6EB",
                  borderRadius: 100,
                  width: "36px",
                  height: "36px",
                  position: "absolute",
                  top: "118px",
                  left: "132px",
                  zIndex: 1,
                }}
                {...getRootProps()}
              >
                <input name="imageUpload" {...getInputProps()} />
                <PhotoCameraIcon
                  sx={{
                    textAlign: "center",
                    color: "#4C4E52",
                    fontWeight: 700,
                  }}
                />
              </Box>
              {selectedFile ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt={selectedFile.name}
                  style={{
                    borderRadius: "100%",
                    margin: 1,
                    width: "168px",
                    height: "168px",
                    display: "flex",
                    justifyContent: "center",
                    border: "3px solid #FEFEFE",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Avatar
                  sx={{ width: 100, height: 100, marginBottom: 3 }}
                  style={{
                    borderRadius: "100%",
                    margin: 1,
                    width: "168px",
                    height: "168px",
                    display: "flex",
                    justifyContent: "center",
                    border: "3px solid #FEFEFE",
                    objectFit: "cover",
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/dependent-images/" +
                    dependent?.FullName?.replace(" ", "-").toLowerCase() +
                    ".png"
                  }
                  {...stringAvatar(dependent?.FullName, {
                    fontSize: "calc(100% + 42px)",
                  })}
                />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Main Content */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Remove Dependent"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "medium",
              color: "#000000",
            }}
          >
            Are you sure you want to remove {dependent?.FullName} as your
            dependent?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            No
          </Button>
          <Button onClick={handleRemove} autoFocus variant="outlined">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          background: "#ECF0F1",
        }}
      >
        <Container
          maxWidth="md"
          style={{ paddingTop: "1px" }}
          sx={{
            paddingY: 5,
          }}
        >
          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            alignItems="stretch"
            marginY={4}
          >
            <Button
              onClick={() => navigate("/funds/add/" + dependent.BadgeNo)}
              type="submit"
              sx={{
                width: "100%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                background: "#F5F5F5",
              }}
              // color="accent"
              // variant="outlined"
            >
              <Typography fontSize="50px">
                <MonetizationOn fontSize="50px" />
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 550,
                  color: "#414177",
                  textTransform: "capitalize",
                  fontFamily: "arial",
                }}
              >
                Add Funds
              </Typography>
            </Button>
            <Button
              onClick={handleOpen}
              sx={{
                width: "100%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                background: "#F5F5F5",
              }}
              // color="accent"
              // variant="outlined"
            >
              <Typography fontSize="50px" background="red">
                <Delete
                  fontSize="lg"
                  style={{
                    background: "red",
                    color: "#FFF",
                    borderRadius: 50,
                    padding: 10,
                  }}
                />
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 550,
                  color: "#414177",
                  textTransform: "capitalize",
                  fontFamily: "arial",
                }}
              >
                Remove
              </Typography>
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography component="h1" variant="h4" fontWeight={500}>
                Dependent Details
              </Typography>
              <Button
                sx={{
                  bgcolor: "#F5F5F5",
                  paddingX: 2,
                  paddingY: 1,
                  borderRadius: 5,
                  fontWeight: 550,
                  fontFamily: "Arial",
                  color: "#1F1F1F",
                  textTransform: "capitalize",
                }}
                onClick={() => navigate("/parent")}
              >
                <Typography>View More</Typography>
              </Button>
            </Box>
            <Box borderRadius={1} bgcolor="#F5F5F5" paddingY={2} paddingX={4}>
              <Box>
                <Typography>Badge No.</Typography>
                <Typography variant="h4" fontWeight={700}>
                  {dependent?.BadgeNo}
                </Typography>
              </Box>
            </Box>
            <Box borderRadius={1} bgcolor="#F5F5F5" paddingY={2} paddingX={4}>
              <Box>
                <Typography>Name</Typography>
                <Typography variant="h4" fontWeight={700}>
                  {dependent?.FullName}
                </Typography>
              </Box>
            </Box>
            <Box borderRadius={1} bgcolor="#F5F5F5" paddingY={2} paddingX={4}>
              <Box>
                <Typography>Current Balance</Typography>
                <Typography variant="h4" fontWeight={700}>
                  {parseFloat(dependent?.Balance)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DependentDetails;
