import React from "react";
import { FormProvider } from "react-hook-form";
import PropTypes from "prop-types";

const HookFormWrapper = ({ children, hookformProps, handleSubmit }) => {
  return (
    <FormProvider {...hookformProps}>
      <form
        onSubmit={hookformProps.handleSubmit(handleSubmit)}
        style={{ width: "100%" }}
      >
        {children}
      </form>
    </FormProvider>
  );
};

HookFormWrapper.prototype = {
  hookformProps: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default HookFormWrapper;
