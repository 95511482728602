import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import * as Yup from "yup";
import { useForm } from "react-hook-form";

import HookFormWrapper from "components/inputs/hook-form-inputs/HookFormWrapper";

import { resolver } from "helpers/globalfunction";
import { BaseInput } from "components/inputs/hook-form-inputs";
import { SERVER_ID, SITE_ID, STORE_ID } from "env";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const validationMessages = {
  required: "This field is required",
};

const schema = {
  firstName: Yup.string().required(validationMessages.required),
  lastName: Yup.string().required(validationMessages.required),
  emailAddress: Yup.string().email().required(validationMessages.required),
  confirEmailAddress: Yup.string()
    .oneOf([Yup.ref("emailAddress"), null], "Doesn't match email address")
    .required(validationMessages.required),
  stepper: Yup.number().default(1),
  mobile: Yup.string().when("stepper", (val) => {
    if (val === 2) {
      return Yup.string()
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
          "Invalid mobile number"
        )
        .required(validationMessages.required);
    }
  }),

  password: Yup.string().when("stepper", (val) => {
    if (val === 2) {
      return Yup.string().required(validationMessages.required);
    }
  }),
  confirmPassword: Yup.string().when("stepper", (val) => {
    if (val === 2) {
      return Yup.string()
        .oneOf([Yup.ref("password"), null], "Doesn't match password")
        .required(validationMessages.required);
    }
  }),
  birthday: Yup.string().when("stepper", (val) => {
    if (val === 3) {
      return Yup.string().required(validationMessages.required);
    }
  }),
  anniversary: Yup.string().when("stepper", (val) => {
    if (val === 3) {
      return Yup.string().required(validationMessages.required);
    }
  }),
};

export default function RegistrationSteps() {
  const [disabled] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const navigate = useNavigate();

  const hookformProps = useForm({
    resolver: resolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      emailAddress: "",
      confirEmailAddress: "",
      stepper: 1,
      mobile: "",
      password: "",
      confirmPassword: "",
      birthday: "",
      anniversary: "",
    },
  });

  const { formState, trigger, setValue, getValues } = hookformProps;

  const steps = [
    {
      label: "Account",
      inputs: (
        <Box
          sx={{ mt: 3 }}
          display="flex"
          flexDirection={"column"}
          gap={2}
          width="100%"
          disabled={disabled}
        >
          <BaseInput
            name="firstName"
            label="First Name"
            type={"name"}
            required={true}
            disabled={disabled}
          />
          <BaseInput
            name="lastName"
            label="Last Name"
            type={"name"}
            required={true}
            disabled={disabled}
          />

          <BaseInput
            name="emailAddress"
            label="Email Address"
            type={"email"}
            required={true}
            disabled={disabled}
          />
          <BaseInput
            name="confirEmailAddress"
            label="Confirm Email Address"
            type={"email"}
            required={true}
            disabled={disabled}
          />
        </Box>
      ),
    },
    {
      label: "Mobile",
      inputs: (
        <Box
          sx={{ mt: 2 }}
          display="flex"
          flexDirection={"column"}
          gap={2}
          width="100%"
        >
          <BaseInput
            name="mobile"
            label="Mobile Number"
            type={"number"}
            required={true}
            disabled={disabled}
          />
          <BaseInput
            name="password"
            label="Password"
            type={"password"}
            required={true}
            disabled={disabled}
          />

          <BaseInput
            name="confirmPassword"
            label="Confirm Password"
            type={"password"}
            required={true}
            disabled={disabled}
          />
        </Box>
      ),
    },
    {
      label: "Personal Details",
      inputs: (
        <Box
          sx={{ mt: 3 }}
          display="flex"
          flexDirection={"column"}
          gap={2}
          width="100%"
        >
          <BaseInput
            name="birthday"
            label="Birthday"
            type={"date"}
            customizer={{ focused: true }}
            required={true}
            disabled={disabled}
          />
          <BaseInput
            name="anniversary"
            label="Anniversary"
            type={"date"}
            customizer={{ focused: true }}
            required={true}
            disabled={disabled}
          />
        </Box>
      ),
    },
  ];

  const handleSubmit = async (formData) => {
    const {
      emailAddress,
      password,
      firstName,
      lastName,
      mobile,
      birthday,
      anniversary,
    } = formData;
    try {
      const res = await axios.post(
        `https://ecommv2.servingintel.com/customer/register-eportal/${SERVER_ID}/${SITE_ID}/${STORE_ID}`,
        {
          emailAddress,
          password,
          firstName,
          lastName,
          mobile,
          birthday,
          anniversary,
        }
      );
      setValue("stepper", getValues("stepper") + 1);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } catch (error) {}
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    const isValid = await trigger(); // Trigger validation for current step
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      setValue("stepper", getValues("stepper") + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setValue("stepper", getValues("stepper") - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <HookFormWrapper
        hookformProps={hookformProps}
        handleSubmit={handleSubmit}
      >
        <Stepper activeStep={activeStep}>
          {steps.map((item, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={item.label} {...stepProps}>
                <StepLabel {...labelProps}>{item.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              Successfully Registered
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={() => navigate(0)}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {steps[activeStep].inputs}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
              <Button
                variant={"outlined"}
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              {activeStep === steps.length - 1 ? (
                <Button
                  type="submit" // Use type="button" to prevent form submission
                  variant="contained"
                  disabled={formState.isSubmitting}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  type="button" // Use type="button" to prevent form submission
                  variant="contained"
                  onClick={handleNext}
                  disabled={formState.isSubmitting}
                >
                  Next{" "}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </HookFormWrapper>
    </Box>
  );
}
