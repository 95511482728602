import React from "react";

import { CircularProgress, Container, Toolbar } from "@mui/material";

const PreLoader = () => {
  return (
    <>
      <Toolbar />
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingY: 2,
          paddingX: 4,
        }}
      >
        <CircularProgress />
      </Container>
    </>
  );
};

export default PreLoader;
