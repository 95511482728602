import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Table,
  Typography,
} from "@mui/material";
import axios from "axios";
import { SERVER_ID, SITE_ID } from "env";
import PreLoader from "components/tools/PreLoader";
import { useCallback } from "react";
import moment from "moment";
import { toCurrency } from "helpers/globalfunction";

const TransactionDetails = ({ open, handleClose, transactionDetails = {} }) => {
  const isSales = Object.hasOwn(transactionDetails, "SaleId");
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [totalSalePrice, setTotalSalePrice] = useState(0);

  const getCheckDetails = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/sale-items/${SERVER_ID}/${SITE_ID}/${transactionDetails.SaleId}`
      );
      setDetails(data);

      const total = data?.reduce((prev, curr) => {
        prev += curr.BasePrice;
        return prev;
      }, 0);

      setTotalSalePrice(total);
    } catch (error) {
      console.log(error, "error getting transactions");
    } finally {
      setLoading(false);
    }
  }, [transactionDetails]);

  useEffect(() => {
    if (!open) return;
    if (isSales) {
      getCheckDetails();
    } else {
      setLoading(false);
    }
  }, [isSales, getCheckDetails, open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title" textAlign="center" marginBottom={2}>
        {isSales ? "Check Details" : "Transaction Details"}
      </DialogTitle>
      {loading ? (
        <PreLoader />
      ) : (
        <>
          <DialogContent>
            {isSales ? (
              <>
                <Box>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>
                      Customer #:{" "}
                      <strong>{transactionDetails.CustomerNumber}</strong>
                    </Typography>
                    <Typography>
                      Date:{" "}
                      <strong>
                        {moment(transactionDetails.ChargeDate).format(
                          "MMM. DD, YYYY"
                        )}
                      </strong>
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    marginBottom={3}
                  >
                    <Typography>
                      Customer:{" "}
                      <strong>
                        {transactionDetails.FirstName}{" "}
                        {transactionDetails.LastName}
                      </strong>
                    </Typography>
                    <Typography>
                      <small>
                        {" "}
                        {moment(transactionDetails.ChargeDate).fromNow()}
                      </small>
                    </Typography>
                  </Stack>
                </Box>
                <Divider
                  sx={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                  }}
                />
                <Box marginTop={2}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {details?.map((item, index) => (
                        <tr>
                          <td>{item.Quantity}</td>{" "}
                          <td>{item.ReceiptDescription}</td>
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            {toCurrency(item.BasePrice)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
                <Divider
                  sx={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                  }}
                />
                <Box marginTop={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    marginBottom={3}
                  >
                    <Typography>Total:</Typography>
                    <Typography>{toCurrency(totalSalePrice || 0)}</Typography>
                  </Stack>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>
                      Customer #:{" "}
                      <strong>{transactionDetails.CustomerNumber}</strong>
                    </Typography>
                    <Typography>
                      Date:{" "}
                      <strong>
                        {moment(transactionDetails.ChargeDate).format(
                          "MMM. DD, YYYY"
                        )}
                      </strong>
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    marginBottom={3}
                  >
                    <Typography>
                      Customer:{" "}
                      <strong>
                        {transactionDetails.FirstName}{" "}
                        {transactionDetails.LastName}
                      </strong>
                    </Typography>
                    <Typography>
                      <small>
                        {" "}
                        {moment(transactionDetails.ChargeDate).fromNow()}
                      </small>
                    </Typography>
                  </Stack>
                </Box>
                <Divider
                  sx={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                  }}
                />
                <Box marginTop={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    marginBottom={3}
                  >
                    <Typography>Total:</Typography>
                    <Typography>
                      {toCurrency(transactionDetails.Amount)}
                    </Typography>
                  </Stack>
                </Box>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

const TransactionDetailsDialog = ({ transactionDetails = {} }) => {
  console.log(transactionDetails, "Trans");
  const [openTransaction, setOpenTransaction] = useState(false);

  const handleClose = () => {
    setOpenTransaction(false);
  };

  return (
    <>
      <Button
        size="small"
        sx={{
          width: "100%",
          maxWidth: "max-content",
          color: "white",
          borderRadius: 5,
        }}
        variant="contained"
        color="accent"
        onClick={() => setOpenTransaction(true)}
      >
        View More
      </Button>

      <TransactionDetails
        open={openTransaction}
        handleClose={handleClose}
        transactionDetails={transactionDetails}
      />
    </>
  );
};

export default TransactionDetailsDialog;
