import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Box, FormHelperText } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import HookFormWrapper from "components/inputs/hook-form-inputs/HookFormWrapper";
import Logo from "assets/images/questfood-logo.png";
import Background from "assets/images/login-background.png";
import ServingIntelLogo from "assets/images/serving-intel-logo.png";

import { resolver } from "helpers/globalfunction";
import { BaseInput } from "components/inputs/hook-form-inputs";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "helpers/hooks/useAuth";
import axios from "axios";
import { SERVER_ID, SITE_ID } from "env";

function Copyright(props) {
  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Link to="https://www.questfms.com/" width={"100%"}>
        <Box
          variant="h5"
          href=""
          sx={{
            width: "100%",
            display: "flex",
            height: "45px",
            margin: "40px auto",
          }}
        >
          <img
            src={ServingIntelLogo}
            alt="Serving Intel Logo"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Link>
    </Box>
  );
}

const schema = {
  emailAddress: Yup.string()
    .required("This field is required")
    .email("Please Enter Valid Email"),
  password: Yup.string().required("This field is required"),
};

const Login = () => {
  const { setAuth } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const hookformProps = useForm({
    resolver: resolver(schema),
    defaultValues: {
      emailAddress: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setDisabled(true);
    try {
      const { data } = await axios.post(
        `https://ecommv2.servingintel.com/customer/login-eportal/${SERVER_ID}/${SITE_ID}`,
        formData
      );
      if (data === 0) {
        setError("Invalid credentials");
        setDisabled(false);
        return;
      }

      localStorage.setItem(
        "auth",
        JSON.stringify({
          user: data,
        })
      );
      setAuth({ user: data });
      navigate(from, { replace: true });
    } catch (error) {}
  };
  return (
    <Container
      maxWidth="100%"
      sx={{
        minHeight: "100%",
        background: `url(${Background})no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        maxWidth={"sm"}
        bgcolor="white"
        borderRadius={5}
        padding={5}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="Questfood Logo" style={{ width: 150 }} />
          <Typography
            component="h1"
            variant="h3"
            fontWeight={"semibold"}
            marginBottom={2}
          >
            Parent Login
          </Typography>
          <HookFormWrapper
            hookformProps={hookformProps}
            handleSubmit={handleSubmit}
          >
            <Box
              sx={{ mt: 1 }}
              display="flex"
              flexDirection={"column"}
              gap={2}
              width="100%"
            >
              <BaseInput
                name="emailAddress"
                label="Email Address"
                type={"email"}
                required={true}
                disabled={disabled}
              />
              <BaseInput
                name="password"
                label="Password"
                type={"password"}
                required={true}
                disabled={disabled}
              />
              {error && (
                <FormHelperText
                  sx={{
                    color: "#d32f2f",
                  }}
                >
                  {error}
                </FormHelperText>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, padding: 2 }}
              >
                Sign In
              </Button>
            </Box>
          </HookFormWrapper>
        </Box>
        <Box
          color={({ palette }) => palette.primary.main}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/register">Create an account</Link>
          <Link to="/forgot">Forgot password?</Link>
        </Box>
        <Copyright sx={{ marginTop: 8, marginBottom: 4 }} />
      </Box>
    </Container>
  );
};
export default Login;
