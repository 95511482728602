import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "helpers/hooks/useAuth";
import background from "assets/images/askme-bg.png";
import logo from "assets/images/questfood-logo.png";
import { Typography, Button, useMediaQuery } from "@mui/material";

const Landing = () => {
  const { auth } = useAuth();

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: `linear-gradient(rgba(255,255,255,.6), rgba(0,0,0,.4)), url(${background})no-repeat center center`,
          backgroundSize: "cover",
          color: "white",
          position: "fixed",
          top: "0px",
          left: "0px",
          padding: "2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <Typography>
            <img src={logo} style={{ width: "200px" }} alt="Quest Food Logo" />
          </Typography>
        </div>
        <Typography
          variant={isMobile ? "p" : "h4"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            marginBottom: "1rem",
            maxWidth: "500px",
          }}
          color="white"
        >
          Ensure Your Children Are Eating Well and Staying on Budget for School
          Meals.
        </Typography>
        <div>
          {!auth?.user ? (
            <Button
              variant="contained"
              sx={{
                borderRadius: "8px",
                marginTop: "1rem",
                padding: "1rem 2rem",
                color: "white",
                width: "100%",
                fontWeight: "500",
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                borderRadius: "8px",
                marginTop: "1rem",
                padding: "1rem 2rem",
                color: "white",
                width: "100%",
                fontWeight: "500",
              }}
              onClick={() => {
                navigate("/parent");
              }}
            >
              Parent Portal
            </Button>
          )}
          <Button
            className="MuiButton-root"
            sx={{
              borderRadius: "8px",
              marginTop: "1rem",
              padding: "1rem 2rem",
              width: "100%",
              fontWeight: "500",
              background: "white",
            }}
            onClick={() => {
              navigate("/askme");
            }}
          >
            Ask Me Anything
          </Button>
        </div>
      </div>
    </>
  );
};
export default Landing;
