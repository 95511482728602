import { Container } from "@mui/material";
import Navbar from "components/tools/Navbar";
import React from "react";
import { Outlet } from "react-router-dom";

const DefaultLayout = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        minHeight: "100%",
        padding: "0!important",
      }}
    >
      <Navbar />
      <Outlet />
    </Container>
  );
};

export default DefaultLayout;
