import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import HookFormWrapper from "components/inputs/hook-form-inputs/HookFormWrapper";
import Logo from "assets/images/questfood-logo.png";
import Background from "assets/images/login-background.png";
import ServingIntelLogo from "assets/images/serving-intel-logo.png";

import { resolver } from "helpers/globalfunction";
import { BaseInput } from "components/inputs/hook-form-inputs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SERVER_ID, SITE_ID, STORE_ID } from "env";
import Swal from "sweetalert2";

function Copyright() {
  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Link to="https://www.questfms.com/" width={"100%"}>
        <Box
          variant="h5"
          href=""
          sx={{
            width: "100%",
            display: "flex",
            height: "45px",
            margin: "40px auto",
          }}
        >
          <img
            src={ServingIntelLogo}
            alt="Serving Intel Logo"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Link>
    </Box>
  );
}

const schema = {
  verification: Yup.string().required("This field is required"),
  password: Yup.string().required("This field is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Doesn't match password")
    .required("This field is required"),
};

const Reset = () => {
  const [disabled, setDisabled] = useState(false);
  const hookformProps = useForm({
    resolver: resolver(schema),
    defaultValues: {
      emailAddress: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setDisabled(true);
    const { verification, password } = formData;
    try {
      const response = await axios.post(
        `https://ecommv2.servingintel.com/customer/eloyalty-reset-password/${SERVER_ID}/${SITE_ID}/${STORE_ID}`,
        { verification, password }
      );
      if (response.data) {
        Swal.fire({
          title: "Success!",
          text: "Your password has been changed.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        navigate("/login");
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Invalid Verification Code.",
          icon: "warning",
          confirmButtonText: "Okay",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisabled(false);
    }
  };
  return (
    <Container
      maxWidth="100%"
      sx={{
        minHeight: "100%",
        background: `url(${Background})no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        maxWidth={"sm"}
        bgcolor="white"
        borderRadius={5}
        padding={5}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="Questfood Logo" style={{ width: 150 }} />
          <Typography
            component="h1"
            variant="h3"
            fontWeight={"semibold"}
            marginBottom={2}
          >
            Enter New Password
          </Typography>
          <Typography variant="subtitle2">
            An email has been sent to your email address. The email has a
            verification code, please paste the verification code in the field
            below to prove that you are the owner of this account.
          </Typography>
          <HookFormWrapper
            hookformProps={hookformProps}
            handleSubmit={handleSubmit}
          >
            <Box
              sx={{ mt: 3 }}
              display="flex"
              flexDirection={"column"}
              gap={2}
              width="100%"
            >
              <BaseInput
                name="verification"
                label="Verification Code"
                required={true}
                disabled={disabled}
              />
              <BaseInput
                name="password"
                label="Password"
                type={"password"}
                required={true}
                disabled={disabled}
              />
              <BaseInput
                name="confirmPassword"
                label="Confirm Password"
                type={"password"}
                required={true}
                disabled={disabled}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, padding: 2 }}
              >
                Reset
              </Button>
            </Box>
          </HookFormWrapper>
        </Box>
        <Box
          color={({ palette }) => palette.primary.main}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/register">Create an account</Link>
          <Link to="/login">Login</Link>
        </Box>
        <Copyright sx={{ marginTop: 8, marginBottom: 4 }} />
      </Box>
    </Container>
  );
};
export default Reset;
