import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  alpha,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowBack, Girl } from "@mui/icons-material";
import useTts from "components/speech/useTts";
import { useNavigate } from "react-router";
import {
  AudioConfig,
  SpeechConfig,
  SpeechSynthesizer,
} from "microsoft-cognitiveservices-speech-sdk";
import { SERVER_ID, SITE_ID } from "env";
import PreLoader from "components/tools/PreLoader";

const greetings = () => {
  const currentTime = new Date();
  const hours = currentTime.getHours();

  if (hours >= 4 && hours < 12) {
    return "morning";
  } else if (hours >= 12 && hours < 18) {
    return "afternoon";
  } else {
    return "evening";
  }
};

const findPhrase = (sentence, phrase) => {
  const regex = new RegExp(phrase, "i");
  const match = sentence.match(regex);
  console.log(match, sentence, phrase);
  return !!match;
};

const Askme = () => {
  const [text, setText, loading, duration] = useTts();
  const [image, setImage] = useState("");
  const [isTimeout, setIsTimeout] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();
  const navigate = useNavigate();
  const timer = useRef(null);

  const messagesEndRef = useRef(null);

  const playTTS = (text) => {
    const speechConfig = SpeechConfig.fromSubscription(
      "3d965505232447a9aa19175d6826ae24",
      "southeastasia"
    );

    speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
    const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
    const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.errorDetails) {
          console.error(result.errorDetails);
        }
      },
      (err) => console.log(err)
    );
  };

  useEffect(() => {
    const apiUrl = "all-revenue-center";
    const url =
      "https://ecommv2.servingintel.com/items/" +
      apiUrl +
      "/" +
      SITE_ID +
      "?server_id=" +
      SERVER_ID;

    axios
      .get(url)
      .then((response) => {
        if (availableProducts !== response.data) {
          setAvailableProducts(response.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (typeof window !== "undefined" && !loading) {
      const speechConfig = SpeechConfig.fromSubscription(
        "3d965505232447a9aa19175d6826ae24",
        "southeastasia"
      );

      speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
      const audioConfig = AudioConfig.fromDefaultSpeakerOutput();
      const synthesizer = new SpeechSynthesizer(speechConfig, audioConfig);

      synthesizer.speakTextAsync(
        `Hello Good ${greetings()}, how may I help you?`,
        (result) => {
          if (result.errorDetails) {
            console.error(result.errorDetails);
          }
          //   setTimeout(() => {
          //     SpeechRecognition.startListening({ continuous: true });
          //   }, Math.round(result.audioDuration / 10000));
        },
        (err) => console.log(err)
      );
    }
  }, [loading]);

  useEffect(() => {
    if (finalTranscript) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        setIsTimeout(true);
      }, 1000);
    }
  }, [finalTranscript]);

  useEffect(() => {
    if (transcript) {
      setInputVal(transcript);
      setIsLoading(true);
    }
  }, [transcript]);

  const handleSend = async () => {
    try {
      const { data } = await axios.post(
        "https://c7b5-69-174-145-114.ngrok.io/webhooks/rest/webhook",
        {
          sender: "default",
          message: inputVal,
        }
      );
      playTTS(data[0]?.text);
      const foundImage = data.find((item) => "image" in item);
      if (foundImage) {
        setImage(foundImage.image);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (duration === 0) return;
    const speakTimeout = setTimeout(() => {
      SpeechRecognition.startListening({ continuous: true });
    }, duration); // Add .5 sec to the duration

    return () => clearTimeout(speakTimeout);
  }, [duration]);

  const apiCall = useCallback(() => {
    SpeechRecognition.stopListening();
    resetTranscript();

    // If no keywords order found call rasa
    if (!findPhrase(finalTranscript, "order")) return callRasa();
    let url = "https://ecommv2.servingintel.com/items";

    if (findPhrase(finalTranscript, "order breakfast")) {
      url +=
        "/revenue-center-serving-categories/" +
        SERVER_ID +
        "/" +
        SITE_ID +
        "/none/Las Olivas/Breakfast Menu";
      return callRestaurant(
        url,
        "Please click the breakfast item that you want to order",
        "DepartmentDescription",
        "DepartmentName"
      );
    } else if (findPhrase(finalTranscript, "order lunch")) {
      url +=
        "/revenue-center-serving-categories/" +
        SERVER_ID +
        "/" +
        SITE_ID +
        "/none/Las Olivas/Lunch Menu";
      return callRestaurant(
        url,
        "Please click the lunch item that you want to order",
        "DepartmentDescription",
        "DepartmentName"
      );
    } else if (findPhrase(finalTranscript, "order dinner")) {
      url +=
        "/revenue-center-serving-categories/" +
        SERVER_ID +
        "/" +
        SITE_ID +
        "/none/Las Olivas/Dinner Menu";
      return callRestaurant(
        url,
        "Please click the dinner item that you want to order",
        "DepartmentDescription",
        "DepartmentName"
      );
    } else {
      url += "/revenue-menu/" + SERVER_ID + "/" + SITE_ID + "/Las Olivas";
      return callRestaurant(url, "Please select from the menu.", "MenuName");
    }
  }, [resetTranscript, finalTranscript, setText]);

  const callRasa = async () => {
    try {
      const { data } = await axios.post(
        "https://c7b5-69-174-145-114.ngrok.io/webhooks/rest/webhook",
        {
          sender: "default",
          message: finalTranscript,
        }
      );

      setText(data[0]?.text);
      const newMessages = [];

      const foundImage = data.find((item) => "image" in item);
      newMessages.push(
        { sender: "user", message: transcript },
        { sender: "ruby", message: data[0]?.text }
      );

      if (foundImage) {
        setImage(foundImage.image);
        newMessages.push({ sender: "ruby", image: foundImage.image });
      }
      setMessages((prev) => [...prev, ...newMessages]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const callRestaurant = async (url, message, key, filterKey) => {
    try {
      const { data } = await axios.get(url);

      if (filterKey) {
        const products = availableProducts.filter((prod) =>
          data.map((item) => item[filterKey]).includes(prod.Department)
        );
        setMessages((prev) => [
          ...prev,
          { sender: "ruby", message },
          { sender: "ruby", products },
        ]);
      } else {
        setMessages((prev) => [
          ...prev,
          { sender: "ruby", message },
          { sender: "ruby", menus: data.map((item) => item[key]) },
        ]);
      }
      setText(message);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isTimeout) {
      apiCall();
      setImage("");
      setIsTimeout(false);
    }
  }, [isTimeout, callRasa]);

  const isMobile = useMediaQuery("(max-width:600px)");

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Box>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Button
          onClick={() => {
            navigate(-1);
          }}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
      </Box>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!loading ? (
          <Container maxWidth="sm">
            <Typography
              fontWeight={isMobile ? "bold" : ""}
              variant={isMobile ? "h3" : "h2"}
              textAlign="center"
            >
              Hi, I'm Ruby!
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                onClick={() => {
                  if (listening) {
                    SpeechRecognition.stopListening();
                  } else {
                    SpeechRecognition.startListening({ continuous: true });
                  }
                }}
                size="large"
                sx={{
                  position: "relative",
                  background: (theme) => theme.palette.grey[200],
                }}
              >
                {listening ? (
                  <Girl
                    sx={{
                      fontSize: isMobile ? "150px" : "200px",
                      color: "#FF007F",
                    }}
                  />
                ) : (
                  <Girl
                    sx={{
                      fontSize: isMobile ? "150px" : "200px",
                      color: "#FF007F",
                    }}
                  />
                )}
                <div
                  className={`${listening ? "pulse" : ""}`}
                  style={{ background: "#FF007F" }}
                ></div>
              </IconButton>
            </Box>
            <Box
              sx={{
                width: "100%",
                background: "white",
                border: "1px solid rgba(0,0,0,.2)",
                borderRadius: 5,
                marginTop: 2,
              }}
            >
              <Box
                borderBottom={1}
                padding={2}
                borderColor={(theme) => theme.palette.grey[400]}
              >
                <Typography variant="h5" component="div">
                  Conversation History
                </Typography>
              </Box>
              <Box
                padding={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: 200,
                  overflowY: "auto",
                  gap: 2,
                }}
              >
                {messages.map((item, index) =>
                  item.sender === "user" ? (
                    <Box
                      bgcolor={(theme) =>
                        alpha(theme.palette.primary.main, 0.15)
                      }
                      paddingY={1}
                      paddingX={2}
                      sx={{
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        borderBottomLeftRadius: 25,
                        alignSelf: "end",
                      }}
                      maxWidth={"80%"}
                    >
                      <Typography variant="h5" component="div">
                        {item.message}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      bgcolor={item.products?.length > 0 ? "white" : "#FF007F"}
                      color="white"
                      paddingY={1}
                      paddingX={2}
                      width="max-content"
                      maxWidth={"80%"}
                      sx={{
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        borderBottomRightRadius: 25,
                        border: "1px solid #FF007F",
                      }}
                    >
                      <Typography variant="h5" component="div">
                        {item.message}
                      </Typography>
                      {item?.image && (
                        <img
                          style={{ maxWidth: "50%", textAlign: "center" }}
                          src={item?.image}
                          alt="Ruby Image"
                        />
                      )}
                      {item?.menus && (
                        <>
                          {item.menus.length > 0 ? (
                            <Stack gap={1} direction="row" flexWrap={"wrap"}>
                              {item.menus.map((menu, index) => (
                                <Chip
                                  label={menu}
                                  variant="outlined"
                                  sx={{
                                    background: "white",
                                    borderColor: "#FF007F",
                                  }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant="h5" component="div">
                              No items on the menu
                            </Typography>
                          )}
                        </>
                      )}

                      {item?.products && (
                        <>
                          {item.products.length > 0 ? (
                            <Stack gap={1} direction="row" flexWrap={"wrap"}>
                              {item.products.map((menu, index) => (
                                <Chip
                                  label={menu.ItemName}
                                  variant="outlined"
                                  sx={{
                                    background: "white",
                                    borderColor: "#FF007F",
                                  }}
                                />
                              ))}
                            </Stack>
                          ) : (
                            <Typography variant="h5" component="div">
                              No items on the menu
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  )
                )}
                {/* {isLoading && (
                  <Box
                    bgcolor={(theme) => alpha(theme.palette.primary.main, 0.15)}
                    paddingY={1}
                    paddingX={2}
                    sx={{
                      borderTopLeftRadius: 25,
                      borderTopRightRadius: 25,
                      borderBottomLeftRadius: 25,
                      alignSelf: "end",
                      marginBottom: 2,
                    }}
                    maxWidth={"80%"}
                  >
                    <Typography variant="h5" component="div">
                      ...
                    </Typography>
                  </Box>
                )} */}
                <div ref={messagesEndRef} />
              </Box>
              <Stack
                sx={{
                  display: "flex",
                  alignItems: "stretch",
                  flexDirection: "row",
                  width: "100%",
                  padding: 2,
                }}
              >
                <TextField
                  fullWidth
                  placeholder="Type here your questions"
                  variant="outlined"
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                />
                {/* <button
                  className="MuiButton-root"
                  style={{
                    borderRadius: "15px",
                    border: "1px solid #FF007F",
                    background: (theme) => theme.pallete.primary.main,
                    padding: "0 1rem",
                    color: "#ffff ! important",
                  }}
                  onClick={handleSend}
                >
                  <span
                    style={{
                      color: "#ffff",
                      fontWeight: "500",
                      fontSize: "1rem",
                    }}
                  >
                    Send
                  </span>
                </button> */}
                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={handleSend}
                >
                  {isLoading ? (
                    <div className="dot-loader">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  ) : (
                    "Send"
                  )}
                </Button>
              </Stack>
            </Box>
          </Container>
        ) : (
          <PreLoader />
        )}
      </Box>
    </Box>
  );
};
export default Askme;
