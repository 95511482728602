import React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Container } from "@mui/material";

const drawerBleeding = 0;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor: grey[100],
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderTopLeftRadius: 30,
  borderTopRightRadius: 30,
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const CreditCardDrawer = (props) => {
  const { window, openDrawer = false, setOpenDrawer, pid } = props;
  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(70% - ${drawerBleeding}px)`,
            overflow: "visible",
            borderTopLeftRadius: 30,
            borderTopRightRadius: 30,
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Container maxWidth="sm" sx={{}}>
          <Puller />

          <StyledBox
            sx={{
              paddingY: 5,
              height: "100%",
              overflow: "auto",
            }}
          >
            <Typography variant="h3" fontWeight={500}>
              Credit Card
            </Typography>
            <div style={{ width: "100%" }}>
              <iframe
                title="credit card"
                src={`https://hc.mercurypay.com/Checkoutiframe.aspx?pid=${pid}`}
                height="450px"
                // scrolling="no"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  overflowX: "hidden",
                  border: 0,
                  scrollbarWidth: 0,
                  padding: 2,
                }}
              ></iframe>
            </div>
          </StyledBox>
        </Container>
      </SwipeableDrawer>
    </Root>
  );
};

CreditCardDrawer.propTypes = {
  window: PropTypes.func,
  openDrawer: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
};

export default CreditCardDrawer;
