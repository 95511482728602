import React, { useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar, IconButton } from "@mui/material";
import { stringAvatar, toCurrency } from "helpers/globalfunction";
import { MonetizationOn } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const AddFunds = ({ dependentData }) => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        size="medium"
        color="accent"
        onClick={() => navigate("/funds/add/" + dependentData.BadgeNo)}
      >
        <MonetizationOn fontSize="medium" />
      </IconButton>
    </>
  );
};

const columns = [
  { id: "BadgeNo", label: "Badge No.", align: "center" },
  { id: "FullName", label: "Full Name" },
  {
    id: "Balance",
    label: "Balance",
    align: "center",
    format: toCurrency,
  },
  { id: "action", label: "", align: "center" },
];

const DependentsTable = ({ dependents = [] }) => {
  const navigate = useNavigate();

  const rows = useMemo(() => {
    const data = dependents.map((item) => {
      if (item.Balance) {
        // Convert the balance string to integer
        item.Balance = parseFloat(item.Balance);
      }
      return {
        ...item,
        action: <AddFunds dependentData={item} />,
      };
    });
    return data;
  }, [dependents]);

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                {columns.map((column) => {
                  const value = row[column.id];
                  if (column.id === "FullName") {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/dependent/" + row.BadgeNo)}
                      >
                        <Avatar
                          style={{ cursor: "pointer" }}
                          alt={`${value} Avatar`}
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/dependent-images/" +
                            value.replace(" ", "-").toLowerCase() +
                            ".png"
                          }
                          {...stringAvatar(value)}
                        />{" "}
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DependentsTable;
