import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import * as Yup from "yup";
import { useForm } from "react-hook-form";

import HookFormWrapper from "components/inputs/hook-form-inputs/HookFormWrapper";
import Logo from "assets/images/questfood-logo.png";
import Background from "assets/images/login-background.png";
import ServingIntelLogo from "assets/images/serving-intel-logo.png";

import { resolver } from "helpers/globalfunction";
import { BaseInput } from "components/inputs/hook-form-inputs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { SERVER_ID, SITE_ID } from "env";
import Swal from "sweetalert2";

function Copyright() {
  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Link to="https://www.questfms.com/" width={"100%"}>
        <Box
          variant="h5"
          href=""
          sx={{
            width: "100%",
            display: "flex",
            height: "45px",
            margin: "40px auto",
          }}
        >
          <img
            src={ServingIntelLogo}
            alt="Serving Intel Logo"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Link>
    </Box>
  );
}

const schema = {
  emailAddress: Yup.string()
    .required("This field is required")
    .email("Please Enter Valid Email"),
};

const Forgot = () => {
  const [disabled, setDisabled] = useState(false);
  const hookformProps = useForm({
    resolver: resolver(schema),
    defaultValues: {
      emailAddress: "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    setDisabled(true);
    try {
      const response = await axios.get(
        `https://ecommv2.servingintel.com/customer/eloyalty-request-reset-password/${SERVER_ID}/${SITE_ID}/${formData.emailAddress}`
      );
      if (response.data) {
        Swal.fire({
          title: "Password Reset Request.",
          text: "Your password reset verification code has been sent to your email. Copy the code and paste it on the Verification Code field.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
        navigate("/reset");
      } else {
        Swal.fire({
          title: "Password Reset Request Failed!",
          text: "Your are not yet registered. Please create your account.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Create",
          cancelButtonText: "Close",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate("/register");
          }
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisabled(false);
    }
  };
  return (
    <Container
      maxWidth="100%"
      sx={{
        minHeight: "100%",
        background: `url(${Background})no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        maxWidth={"sm"}
        bgcolor="white"
        borderRadius={5}
        padding={5}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="Questfood Logo" style={{ width: 150 }} />
          <Typography
            component="h1"
            variant="h3"
            fontWeight={"semibold"}
            marginBottom={2}
          >
            Reset Password
          </Typography>
          <Typography variant="subtitle2">
            Please enter the email address for your account. A verification code
            will be sent to you. Once you have received the verification code,
            you will be able to choose a new password for your account.
          </Typography>
          <HookFormWrapper
            hookformProps={hookformProps}
            handleSubmit={handleSubmit}
          >
            <Box
              sx={{ mt: 3 }}
              display="flex"
              flexDirection={"column"}
              gap={2}
              width="100%"
            >
              <BaseInput
                name="emailAddress"
                label="Email Address"
                type={"email"}
                required={true}
                disabled={disabled}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, padding: 2 }}
              >
                Reset
              </Button>
            </Box>
          </HookFormWrapper>
        </Box>
        <Box
          color={({ palette }) => palette.primary.main}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Link to="/register">Create an account</Link>
          <Link to="/login">Login</Link>
        </Box>
        <Copyright sx={{ marginTop: 8, marginBottom: 4 }} />
      </Box>
    </Container>
  );
};
export default Forgot;
