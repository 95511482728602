import { ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Toolbar,
  Typography,
} from "@mui/material";
import axios from "axios";
import { BaseInput } from "components/inputs/hook-form-inputs";
import HookFormWrapper from "components/inputs/hook-form-inputs/HookFormWrapper";
import PreLoader from "components/tools/PreLoader";
import { SERVER_ID, SITE_ID } from "env";
import { resolver, stringAvatar, toCurrency } from "helpers/globalfunction";
import useAuth from "helpers/hooks/useAuth";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import CreditCardDrawer from "./CreditCardDrawer";

const schema = {
  amount: Yup.number()
    .required("This field is required")
    .min(0.02, "Amount must be greated than $0.01")
    .typeError("Invalid input"),
};

// const creditCardBody = {
//   OrderID: "001",
//   RestaurantAddress: "2075 Prairie Street, St. Charles, IL",
//   RestaurantName: "Questfood",
//   ReturnURL: "https://ajsketofactory.com/thankyou.php",
//   Tax: 0.08,
//   Total: 12.905999999999999,
//   ZipCode: "60174",
// };
const AddFunds = () => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const params = useParams();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pid, setPid] = useState(null);
  const [dependent, setDependent] = useState({});
  const [loading, setLoading] = useState(true);

  const [disabled, setDisabled] = useState(false);

  const getDependentDetails = useCallback(async () => {
    try {
      // const { data } = await axios.get(
      //   `https://ecommv2.servingintel.com/customer/dependents/${SERVER_ID}/${SITE_ID}/${params?.dependentId}`
      // );

      // Workaround
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/dependents/${SERVER_ID}/${SITE_ID}/${auth?.user?.CustomerNumber}`
      );
      const dependentDetails = data.find(
        (dep) => dep.BadgeNo === parseInt(params?.dependentId)
      );
      setDependent(dependentDetails);
      setLoading(false);
    } catch (error) {
      console.log(error, "error getting dependents");
    }
  }, [params, auth]);

  useEffect(() => {
    getDependentDetails();
  }, [getDependentDetails]);

  const hookformProps = useForm({
    resolver: resolver(schema),
    defaultValues: {
      amount: 0,
    },
  });

  // WORK AROUND
  const onSubmit = async (formData) => {
    setDisabled(true);
    const body = {
      amount: formData.amount,
      customerNumber: dependent.BadgeNo,
    };

    try {
      const { data } = await axios.post(
        `https://ecommv2.servingintel.com/customer/add-balance/${SERVER_ID}/${SITE_ID}`,
        body
      );
      console.log(data, "bod");
      getDependentDetails();
    } catch (error) {
      console.log(error, "pid");
    } finally {
      setDisabled(false);
    }
  };

  //  TODO HANDLE ADD FUNDS SUBMIT
  // const onSubmit = async (formMethod) => {
  //   setDisabled(true);
  //   creditCardBody.Total = formMethod.amount;
  //   try {
  //     const { data } = await axios.post(
  //       "https://ecommv2.servingintel.com/payment/initialize/89",
  //       creditCardBody
  //     );
  //     setPid(data);
  //     setOpenDrawer(true);
  //   } catch (error) {
  //     console.log(error, "pid");
  //   } finally {
  //     setDisabled(false);
  //   }
  // };

  if (loading) {
    return <PreLoader />;
  }
  return (
    <>
      <Box
        minWidth={"100%"}
        sx={{
          height: 350,
          // background: `linear-gradient(rgba(100, 161, 74, .6) ,rgba(46, 104, 21, 1) )`,
          background: `#F5F5F5`,
          backgroundSize: "cover",
          // borderBottomLeftRadius: 30,
          // borderBottomRightRadius: 30,
        }}
      >
        <Toolbar />

        <Container
          maxWidth="md"
          sx={{
            paddingY: 2,
            paddingX: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 0,
          }}
        >
          <Box>
            <IconButton onClick={() => navigate("/parent")}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            color="white"
          >
            <Avatar
              alt={dependent?.FullName}
              src={
                process.env.PUBLIC_URL +
                "/assets/images/dependent-images/" +
                dependent?.FullName?.replace(" ", "-").toLowerCase() +
                ".png"
              }
              {...stringAvatar(dependent?.FullName, {
                width: 100,
                height: 100,
                marginBottom: 3,
                marginTop: -1,
                fontSize: "calc(100% + 30px)",
              })}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, color: "#000" }}
            >
              Current Balance:
            </Typography>
            <Typography
              component="h2"
              variant="h1"
              sx={{ fontSize: "50px", fontWeight: 500, color: "#000" }}
            >
              {toCurrency(parseFloat(dependent.Balance))}
              <Typography component="span" variant="subtitle1">
                USD
              </Typography>
            </Typography>
          </Box>
        </Container>
        <Box
          sx={{
            background: "#ECF0F1",
            height: "100vh",
          }}
        >
          <Container
            maxWidth="md"
            sx={{
              padding: 2,
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{ marginY: 1, fontWeight: 500 }}
            >
              Add Funds
            </Typography>

            <HookFormWrapper
              hookformProps={hookformProps}
              handleSubmit={onSubmit}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="stretch"
                marginY={5}
                gap={2}
              >
                <BaseInput
                  name="amount"
                  label="Amount"
                  type={"number"}
                  required={true}
                  disabled={disabled}
                  customInputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
                <Button fullWidth variant="contained" type="submit">
                  Add Funds
                </Button>
              </Box>
            </HookFormWrapper>
            <Box display="flex" flexDirection="column" gap={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography component="h1" variant="h4" fontWeight={500}>
                  Dependent Details
                </Typography>
                <Button
                  sx={{
                    bgcolor: "#F5F5F5",
                    paddingX: 2,
                    paddingY: 1,
                    borderRadius: 5,
                    fontWeight: 550,
                    fontFamily: "Arial",
                    color: "#1F1F1F",
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate("/parent")}
                >
                  <Typography>View More</Typography>
                </Button>
              </Box>
              <Box borderRadius={1} bgcolor="#F5F5F5" paddingY={2} paddingX={4}>
                <Box>
                  <Typography>Badge No.</Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {dependent.BadgeNo}
                  </Typography>
                </Box>
              </Box>
              <Box borderRadius={1} bgcolor="#F5F5F5" paddingY={2} paddingX={4}>
                <Box>
                  <Typography>Name</Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {dependent.FullName}
                  </Typography>
                </Box>
              </Box>
              <Box borderRadius={1} bgcolor="#F5F5F5" paddingY={2} paddingX={4}>
                <Box>
                  <Typography>Current Balance</Typography>
                  <Typography variant="h4" fontWeight={700}>
                    {toCurrency(parseFloat(dependent.Balance))}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <CreditCardDrawer
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              pid={pid}
            />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default AddFunds;
