import { Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import {
  AddFunds,
  Home,
  Login,
  Parent,
  Register,
  Thankyou,
  Landing,
  Askme,
  Profile,
  Forgot,
  Reset,
  Transactions,
} from "pages";

import theme from "./theme";
import DefaultLayout from "layouts/DefaultLayout";
import RequireAuth from "components/tools/RequireAuth";
import AddDependent from "pages/dependent/add/AddDependent";
import DependentDetails from "pages/dependent/details/DependentDetails";

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="app">
      <main className="content">
        <Routes>
          {/* Protected Routes*/}
          <Route element={<RequireAuth />}>
            <Route element={<DefaultLayout />}>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/parent" element={<Parent />} />
              <Route exact path="/parent/profile" element={<Profile />} />
              <Route
                exact
                path="/funds/add/:dependentId"
                element={<AddFunds />}
              />
              <Route
                exact
                path="/dependent/:dependentId"
                element={<DependentDetails />}
              />
              <Route exact path="/dependent/add" element={<AddDependent />} />
              <Route exact path="/transactions" element={<Transactions />} />
            </Route>
          </Route>
          <Route exact path="/landing" element={<Landing />} />
          <Route exact path="/askme" element={<Askme />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot" element={<Forgot />} />
          <Route exact path="/reset" element={<Reset />} />
          <Route exact path="/thankyou" element={<Thankyou />} />
          <Route exact path="/register" element={<Register />} />
        </Routes>
      </main>
    </div>
  </ThemeProvider>
);

export default App;
