import React, { useEffect } from "react";
import { Box, Button, Container, Typography } from "@mui/material";

import HeroImage from "assets/images/home-hero.png";
import { FamilyRestroom, Language } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Home = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        minWidth={"100%"}
        sx={{
          height: 300,
          background: `linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, .1)),url(${HeroImage})no-repeat center center`,
          backgroundSize: "cover",
        }}
      ></Box>
      <Box
        sx={{
          marginTop: "-40px",
          background: "#fff",
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            padding: 4,
          }}
        >
          <Box>
            <Typography component="h1" variant="h4" fontWeight={"500"}>
              Welcome to
              <br />
              <Typography
                component="span"
                variant="h4"
                fontWeight={"600"}
                color="primary"
              >
                Quest Food Student and Parent APP
              </Typography>
            </Typography>
            <Typography
              component="p"
              variant="h6"
              fontWeight={"500"}
              marginY={2}
            >
              This app is a comprehensive solution for busy parents who want to
              ensure their children are eating well and paying the right budget
              for their meal at school.
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={2}
            justifyContent="center"
            alignItems="stretch"
            marginTop={4}
          >
            <Button
              onClick={() => navigate("/parent")}
              sx={{
                width: "100%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
              }}
              variant="contained"
            >
              <Typography fontSize="50px">
                <FamilyRestroom fontSize="lg" />
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                Parent Portal
              </Typography>
            </Button>
            <Button
              onClick={() =>
                (window.location.href = "https://www.questfms.com")
              }
              sx={{
                width: "100%",
                padding: 2,
                display: "flex",
                flexDirection: "column",
              }}
              variant="outlined"
            >
              <Typography fontSize="50px">
                <Language fontSize="lg" />
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                Our Website
              </Typography>
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Home;
