import React from "react";

import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Logo from "assets/images/questfood-logo.png";
import Background from "assets/images/login-background.png";
import ServingIntelLogo from "assets/images/serving-intel-logo.png";

import RegistrationSteps from "./RegistrationSteps";

function Copyright(props) {
  return (
    <Box
      sx={{
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Link to="https://www.questfms.com/" width={"100%"}>
        <Box
          variant="h5"
          href=""
          sx={{
            width: "100%",
            display: "flex",
            height: "45px",
            margin: "40px auto",
          }}
        >
          <img
            src={ServingIntelLogo}
            alt="Serving Intel Logo"
            style={{ height: "100%", objectFit: "cover" }}
          />
        </Box>
      </Link>
    </Box>
  );
}

const Register = () => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        minHeight: "100%",
        background: `url(${Background})no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        width={"100%"}
        maxWidth={"sm"}
        bgcolor="white"
        borderRadius={5}
        padding={5}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={Logo} alt="Questfood Logo" style={{ width: 150 }} />
          <Typography
            component="h1"
            variant="h3"
            fontWeight={"semibold"}
            marginBottom={2}
          >
            Parent Registration
          </Typography>
          {/* <HookFormWrapper
            hookformProps={hookformProps}
            handleSubmit={handleSubmit}

          >
            <Box
              sx={{ mt: 1 }}
              display="flex"
              flexDirection={"column"}
              gap={2}
              width="100%"
            >
              <BaseInput
                name="emailAddress"
                label="Email Address"
                type={"email"}
                required={true}
                disabled={disabled}
              />
              <BaseInput
                name="password"
                label="Password"
                type={"password"}
                required={true}
                disabled={disabled}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, padding: 2 }}
              >
                Register
              </Button>
            </Box>
          </HookFormWrapper> */}
          <RegistrationSteps />
        </Box>
        <Box>
          <Typography>
            You have an account?
            <Typography
              component={"span"}
              color={({ palette }) => palette.primary.main}
            >
              {" "}
              <Link to="/login">Login</Link>
            </Typography>
          </Typography>
        </Box>
        <Copyright sx={{ marginTop: 8, marginBottom: 4 }} />
      </Box>
    </Container>
  );
};
export default Register;
