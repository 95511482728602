import React from "react";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { InputAdornment, TextField } from "@mui/material";

const BaseInput = ({
  name = "",
  label = "",
  type = "text",
  variant = "outlined",
  helperText = "",
  required = false,
  disabled = false,
  customizer = {},
  customInputProps = {},
}) => {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const fieldError = errors[name];
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={getValues(name)}
      render={({ field }) => (
        <TextField
          {...field}
          {...customizer}
          InputProps={customInputProps}
          sx={{
            fontWeight: 600,
            border: "0px",
            width: "100%",
            ...customizer?.sx,
          }}
          label={label ? `${label} ${required ? "*" : ""}` : ""}
          type={type}
          variant={variant}
          error={!!fieldError}
          helperText={fieldError ? fieldError?.message : helperText}
          disabled={disabled}
        />
      )}
    ></Controller>
  );
};

BaseInput.prototype = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  customizer: PropTypes.object,
};

export default BaseInput;
