import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "helpers/hooks/useAuth";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";

import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";

import HookFormWrapper from "components/inputs/hook-form-inputs/HookFormWrapper";

import { resolver } from "helpers/globalfunction";
import { BaseInput } from "components/inputs/hook-form-inputs";
import axios from "axios";
import { SERVER_ID, SITE_ID, STORE_ID } from "env";

const AddDependent = () => {
  const { auth } = useAuth();

  const navigate = useNavigate();
  const schema = {
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const hookformProps = useForm({
    resolver: resolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const handleSubmit = async (formData) => {
    // ParentCustomerId, firstName, lastName
    const body = {
      ...formData,
      parentCustomerNumber: auth?.user?.CustomerNumber,
    };

    try {
      const res = await axios.post(
        `https://ecommv2.servingintel.com/customer/add-dependent/${SERVER_ID}/${SITE_ID}`,
        body
      );
      console.log(res, "add dependents");
    } catch (error) {
      console.log(error, "add dependent error");
    }
    setDisabled(true);
    navigate("/parent");
  };
  const handleCancel = () => {
    navigate("/parent");
  };
  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  return (
    <>
      <Box
        minWidth={"100%"}
        sx={{
          height: 350,
          // background: `linear-gradient(rgba(100, 161, 74, .6) ,rgba(46, 104, 21, 1) )`,
          background: `#F5F5F5`,
          backgroundSize: "cover",
          // borderBottomLeftRadius: 50,
          // borderBottomRightRadius: 50,
        }}
      >
        <Toolbar />

        <Container
          maxWidth="md"
          sx={{
            paddingY: 2,
            paddingX: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 0,
          }}
        >
          <Box>
            <IconButton onClick={() => navigate("/parent")} color="inherit">
              <ChevronLeft />
            </IconButton>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Box
              sx={{
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                position: "relative",
              }}
              flexDirection={"column"}
              gap={2}
              width="fit-content"
              margin="auto"
            >
              <Box
                sx={{
                  padding: 1,
                  background: "#E4E6EB",
                  borderRadius: 100,
                  width: "36px",
                  height: "36px",
                  position: "absolute",
                  top: "118px",
                  left: "132px",
                  zIndex: 1,
                }}
                {...getRootProps()}
              >
                <input name="imageUpload" {...getInputProps()} />
                <PhotoCameraIcon
                  sx={{
                    textAlign: "center",
                    color: "#4C4E52",
                    fontWeight: 700,
                  }}
                />
              </Box>
              {selectedFile ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt={selectedFile.name}
                  style={{
                    borderRadius: "100%",
                    margin: 1,
                    width: "168px",
                    height: "168px",
                    display: "flex",
                    justifyContent: "center",
                    border: "3px solid #FEFEFE",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Avatar
                  sx={{ width: 100, height: 100, marginBottom: 3 }}
                  style={{
                    borderRadius: "100%",
                    margin: 1,
                    width: "168px",
                    height: "168px",
                    display: "flex",
                    justifyContent: "center",
                    border: "3px solid #FEFEFE",
                    objectFit: "cover",
                  }}
                />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: "#ECF0F1",
          height: "100vh",
        }}
      >
        <Container maxWidth="md" style={{ padding: "25px 20px" }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{ margin: "15px 0px", fontWeight: 500 }}
          >
            Add Dependent
          </Typography>

          <HookFormWrapper
            hookformProps={hookformProps}
            handleSubmit={handleSubmit}
          >
            <Box sx={{ background: "#FFF", marginY: 2, borderRadius: "4px" }}>
              <BaseInput
                customizer={{ sx: { "& fieldset": { border: "none" } } }}
                name="firstName"
                label="First Name"
                type={"name"}
                required={true}
                disabled={disabled}
              />
            </Box>
            <Box sx={{ background: "#FFF", marginY: 2, borderRadius: "4px" }}>
              <BaseInput
                customizer={{ sx: { "& fieldset": { border: "none" } } }}
                name="lastName"
                label="Last Name"
                type={"name"}
                required={true}
                disabled={disabled}
              />
            </Box>
            <Box
              display="flex"
              gap={2}
              justifyContent="center"
              alignItems="stretch"
              marginTop={4}
            >
              <Button
                // onClick={handleClick}
                type="submit"
                sx={{
                  width: "100%",
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="contained"
              >
                <Typography fontSize="50px">
                  <SaveAltOutlinedIcon fontSize="lg" />
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  Save
                </Typography>
              </Button>
              <Button
                onClick={handleCancel}
                sx={{
                  width: "100%",
                  padding: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
                variant="outlined"
              >
                <Typography fontSize="50px">
                  <CancelOutlinedIcon fontSize="lg" />
                </Typography>
                <Typography variant="subtitle1" fontWeight={500}>
                  Cancel
                </Typography>
              </Button>
            </Box>
          </HookFormWrapper>
        </Container>
      </Box>
    </>
  );
};

export default AddDependent;
